<template>
    <div class="MediaApplication">
        <el-page-header @back="$router.go(-1)" content="媒体认证"></el-page-header>
        <div class="breadcrumb">
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/CommunityIndex' }">社区</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/IndustryTrendsList' }">行业动态</el-breadcrumb-item>
                <el-breadcrumb-item>媒体认证</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="banner f-c-c mb-40">
            <span class="name">媒体认证</span>
        </div>
        <div class="main">
            <!--  -->
            <div class="f-cl-c">
                <div class="formTitle">媒体信息</div>
                <div>
                    <el-form class="f-w w--100" ref="ruleForm" :model="ruleForm" label-width="100px"
                        :rules="mediaResume">
                        <el-form-item class=" w--50" v-for="(item, index) of mediaFormItemArr" :label="item.label"
                            :prop="item.prop" :key="index">
                            <div v-if="item.prop == 'salary'">
                                <salarySelsect @handlesalarySelsect="handlesalarySelsect" :key="new Date().getTime()"
                                    :min="ruleForm.minsalary" :max="ruleForm.maxsalary">
                                </salarySelsect>
                            </div>
                            <el-select style="width: 340px;" v-else-if="item.isSelect" v-model="ruleForm[item.prop]">
                                <el-option v-for="item1 in item.optionList" :key="item1.name" :label="item1.name"
                                    :value="item1.value"></el-option>
                            </el-select>
                            <el-input style="width: 340px;" v-else-if="item.is30WideCharacter" maxlength="30"
                                type="text" v-model="ruleForm[item.prop]"
                                :placeholder="item.placeholderValue"></el-input>
                            <ChooseSecondLevelCity style="width: 340px !important;" v-else-if="item.isCity"
                                :city="ruleForm.cityList" @handleChangeCity="handleChangeCity"
                                :key="new Date().getTime()">
                            </ChooseSecondLevelCity>
                            <!-- <el-input v-else-if="item.istextarea" style="width: 1000px;" type="textarea" v-model="ruleForm[item.prop]"
                                :placeholder="item.placeholderValue" maxlength="100" clearable :show-word-limit="true"></el-input> -->
                        </el-form-item>
                        <el-form-item label="媒体简介" prop="introduction" key="999">
                            <el-input style="width: 1000px;" type="textarea" v-model="ruleForm.introduction"
                                placeholder="编辑媒体简介" maxlength="100" clearable :show-word-limit="true"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="formTitle mt-20">上传审核资料</div>
                <el-form class="f-w" ref="fileForm" :model="ruleForm" :rules="mediaResume" label-width="120px"
                    :label-position="'left'">
                    <el-form-item label="主页图片" prop="homeImgFile">
                        <NewUploadOtherImage :imageUrl="homeImgFileImg" :imageDiff="'homeImgFile'"
                            :disabled="disabledChange" @FormData="FormData" @deleteUploadImage="deleteUploadImage"
                            key="999">
                        </NewUploadOtherImage>
                    </el-form-item>
                    <el-form-item style="margin-left: 284px;" label="证书图片" prop="certificateImg">
                        <NewUploadOtherImage :imageUrl="certificateImg" :imageDiff="'certificateImg'"
                            :disabled="disabledChange" @FormData="FormData" @deleteUploadImage="deleteUploadImage"
                            key="9999">
                        </NewUploadOtherImage>
                    </el-form-item>
                </el-form>
                <!-- <div class="w--100 f-c-c"> -->
                    <div v-if="MediumStateInfo.mediumState == '00'" class="f-c-c saveBtn" @click="applicantMediumAudit()">申请认证</div>
                    <div v-if="MediumStateInfo.mediumState == '10'" class="f-c-c saveBtn" @click="reviseForumMedium()">确认修改</div>
                    <div v-if="MediumStateInfo.mediumState == '30' || MediumStateInfo.mediumState == '31'" class="f-c-c saveBtn" @click="reviseMediumAudit()">确认修改</div>
                <!-- </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mediaResume } from "@/utils/FormRules";
export default {
    name: 'MediaApplication',

    data() {
        return {
            mediaResume,  // 简历规则
            ruleForm: {
                mediumName: '',
                contact: '',
                contactPhone: '',
                city: '',
                cityList: [],
                address: '',
                introduction: '',
                homeImgFile: '',  // 主页图片
                certificateImg: '',  // 证书图片
            },

            mediaFormItemArr: [
                {
                    label: '媒体名字',
                    prop: 'mediumName',
                    placeholderValue: '请输入媒体名字',
                    is30WideCharacter: true
                },
                {
                    label: '联系人',
                    prop: 'contact',
                    placeholderValue: '请输入联系人',
                    is30WideCharacter: true
                },
                {
                    label: '联系电话',
                    prop: 'contactPhone',
                    placeholderValue: '请输入联系电话',
                    is30WideCharacter: true
                },
                {
                    label: '所在城市',
                    prop: 'city',
                    isCity: true
                },
                {
                    label: '详细地址',
                    prop: 'address',
                    placeholderValue: '请输入详细地址',
                    is30WideCharacter: true
                },
            ],
            disabledChange: false,
            homeImgFileImg: '',
            homeImgFileImg2: '',
            certificateImg: '',
            certificateImg2: '',
            MediumStateInfo: {},  // 媒体信息
            MediumInfo:{}
        }
    },
    // findForumMedium
    created() {
        this.MediumStateInfo = this.$getStorage('MediumStateInfo');
        // let { mediumState } = this.MediumStateInfo;
        // if (mediumState == '10') {
        //     this.findForumMedium();
        // }
        let { MediumInfo } = JSON.parse(this.$UrlEncode.decode(this.$route.query.key)) ?? {};
        this.MediumInfo = MediumInfo;
        let { mediumName, contact, contactPhone, city, address, introduction, homeImg, certificateImg } = MediumInfo;
        this.ruleForm = {
                mediumName,
                contact,
                contactPhone,
                city,
                cityList: city.split(" "),
                address,
                introduction,
                homeImgFile: homeImg,  // 主页图片
                oldhomeImgFile: homeImg,  // 主页图片
                certificateImg: certificateImg,  // 证书图片
                oldcertificateImg: certificateImg,  // 证书图片
            },
            this.homeImgFileImg = homeImg;
            this.certificateImg = certificateImg;
    },
    methods: {
        handleChangeCity(value) {
            this.ruleForm.city = value.join(' ');
            this.ruleForm.cityList = value;
        },
        FormData(params, type) {
            let that = this;
            var formData = new FormData();
            formData.append("file", params.file);
            formData.append("fileState", type == 'homeImgFile' ? 1 : 2);
            that.uploadMediumAuditFile(formData, type);
        },
        // 媒体认证上传文件接口
        uploadMediumAuditFile(formData, type) {
            let that = this;
            that.$http.uploadMediumAuditFile(formData).then(res => {
                if (res.code == 200) {
                    that.$common.message(res.msg, "success");
                    // 传递使用img 显示使用imgSts
                    this.ruleForm[type] = res.data.img;
                    if (type == 'homeImgFile') {
                        that.homeImgFileImg = res.data.imgSts;
                    } else {
                        that.certificateImg = res.data.imgSts;
                    }
                    that.clearItemValidate(type);
                }
            });
        },
        // 清除某一项的验证
        clearItemValidate(value) {
            this.$refs.fileForm.fields.map(i => {
                if (i.prop == value) {
                    i.clearValidate();
                }
            });
        },
        // 删除上传的图片
        deleteUploadImage(data) {
            this.ruleForm[data] = '';
            if (data == 'homeImgFile') {
                this.homeImgFileImg = '';
            } else {
                this.certificateImg = '';
            }
        },
        // 用户申请媒体审核接口
        applicantMediumAudit() {
            let that = this,
                ruleFormValid = '',
                fileFormValid = '';
            that.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    ruleFormValid = valid;
                } else {
                    return false;
                }
            });
            that.$refs.fileForm.validate((valid) => {
                if (valid) {
                    fileFormValid = valid;
                } else {
                    return false;
                }
            });
            if (ruleFormValid && fileFormValid) {
                let data = that.ruleForm;
                delete data.cityList;
                that.$confirm('确定申请？', '提示').then(() => {
                    that.$http.applicantMediumAudit({
                        ...data,
                    }).then(res => {
                        that.$common.message(res.msg, 'success')
                        that.$router.go(-1);

                    })
                }).catch(() => { })
            }
        },
        // 用户修改媒体
        reviseForumMedium(){
            let that = this,
                ruleFormValid = '',
                fileFormValid = '';
            that.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    ruleFormValid = valid;
                } else {
                    return false;
                }
            });
            that.$refs.fileForm.validate((valid) => {
                if (valid) {
                    fileFormValid = valid;
                } else {
                    return false;
                }
            });
            if (ruleFormValid && fileFormValid) {
                let { mediumName,contact,contactPhone,city,address,introduction,homeImgFile,oldhomeImgFile,certificateImg,oldcertificateImg } = that.ruleForm,
                data = {
                    mediumName,
                    contact,
                    contactPhone,
                    city,
                    address,
                    introduction,
                    homeImg: homeImgFile == oldhomeImgFile ? '' : homeImgFile,
                    certificateImg: certificateImg == oldcertificateImg ? '' : certificateImg,
                    mediumId: that.MediumInfo.mediumId
                }
                that.$confirm('确定修改？', '提示').then(() => {
                    that.$http.reviseForumMedium({
                        ...data
                    }).then(res => {
                        that.$common.message(res.msg, 'success')
                        that.$router.go(-1);
                    })
                }).catch(() => { })
            }
        },
        reviseMediumAudit(){
            let that = this,
                ruleFormValid = '',
                fileFormValid = '';
            that.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    ruleFormValid = valid;
                } else {
                    return false;
                }
            });
            that.$refs.fileForm.validate((valid) => {
                if (valid) {
                    fileFormValid = valid;
                } else {
                    return false;
                }
            });
            if (ruleFormValid && fileFormValid) {
                let { mediumName,contact,contactPhone,city,address,introduction,homeImgFile,oldhomeImgFile,certificateImg,oldcertificateImg } = that.ruleForm,
                data = {
                    mediumName,
                    contact,
                    contactPhone,
                    city,
                    address,
                    introduction,
                    homeImg: homeImgFile == oldhomeImgFile ? '' : homeImgFile,
                    certificateImg: certificateImg == oldcertificateImg ? '' : certificateImg,
                    mediumAuditId: that.MediumInfo.mediumAuditId
                }
                that.$confirm('确定修改？', '提示').then(() => {
                    that.$http.reviseMediumAudit({
                        ...data
                    }).then(res => {
                        that.$common.message(res.msg, 'success')
                        that.$router.go(-2);
                    })
                }).catch(() => { })
            }
        }
    },
}

</script>
<style lang='less' scoped>
.MediaApplication {
    .breadcrumb {
        padding: 10px 0;
        border-bottom: 1px solid #FBFBFB;
    }

    .el-breadcrumb ::v-deep .el-breadcrumb__inner {
        color: #666666 !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
        color: #0363FA !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .banner {
        background: url('../../../assets/Community/IndustryTrends/bannerMediaAuthentication.png');
        height: 100px;
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 40px;

        .name {
            font-size: 36px;
        }
    }

    .main {
        .formTitle {
            width: 100%;
            margin-left: 60px;
            margin-bottom: 22px;
            border-left: 4px solid #0363FA;
            padding-left: 10px;
            font-size: 24px;
            font-weight: 500;
            color: #333333;
        }

        .saveBtn {
            width: 160px;
            height: 50px;
            border-radius: 10px;
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
            color: #FFF;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            cursor: pointer;
        }
    }
}
</style>